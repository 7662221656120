<template>
	<div class="page">
		<Head />
		<div class="index-banner">
			<swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
				<swiper-slide v-for="(item,index) in slide.length" :key='index'>
					<!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
					<img :src="slide[index]" alt="">
				</swiper-slide>
			</swiper>
		</div>
		<div v-for="(item,index) in pageitems" :key="index" class="{'z-warp': item.item_type == 'left', 'hot-product': item.item_type == 'Three_column', 'm-warp':item.item_type == 'analysis'}, 'section':item.item_type == 'picture'">
			
			<div v-if="item.item_type == 'onlytitle'" class="steps-cell-enter" >
				<p>{{item.title}}</p>				
			</div>
		
			<div v-if="item.item_type == 'left'" class="steps-cell gc-cell flex flex-zBetween ">
				<div class="flex company-info">
					<div  class="company-info-content">
						<!-- <div class="company-info-tit">{{item.title}} </div> -->
						<div class="steps-icon">
							<img src="../assets/images/set.png">
						</div>
						<div class="flex steps-top">
							<div  class="steps-cell-tit">
								{{item.title}}
							</div>
						</div>
						<div class="company-info-desc" >
							<div v-html="item.content"></div>
						</div>						
					</div>
					<div class="flex_bd  company-info-pic">
						<div class="com-pic" v-for="(itx,ind) in item.pic" :key="ind">
							<img  :src="itx">
						</div>
					</div>
				</div>
				<!-- <div class="gc-lt">
					<div class="steps-cell-hd">
						<div class="steps-icon">
							<img src="../assets/images/set.png">
						</div>
						<div class="flex steps-top">
							<div  class="steps-cell-tit">
								{{item.title}}
							</div>
						</div>
						<div class="steps-cell-desc">
							<div v-html="item.content"></div>
						</div>						
						<div class="steps-block">
						</div>
					</div>
				</div>
				<div class="gc-rt">
					<div class=" pull-left1 com-box" v-if="item.pic.length>0">
						<div class="com-pic" v-for="(itx,ind) in item.pic" :key="ind">
							<img  :src="itx">
						</div>
					</div>
				</div> -->
			</div>
			<div v-if="item.item_type == 'right'" class="steps-cell mold-cell flex-center flex-zBetween flex-zRightLeft">
				<div class="flex company-info">
					<div class="flex_bd  company-info-pic">
						<div class="com-pic" v-for="(itx,ind) in item.pic" :key="ind">
							<img  :src="itx">
						</div>
					</div>
					<div  class="company-info-content">
						<!-- <div class="company-info-tit">{{item.title}} </div> -->
						<div class="steps-icon">
							<img src="../assets/images/set.png">
						</div>
						<div class="flex steps-top">
							<div  class="steps-cell-tit">
								{{item.title}}
							</div>
						</div>
						<div class="company-info-desc" >
							<div v-html="item.content"></div>
						</div>						
					</div>
					
				</div>
			</div>
			<div class="container" v-if="item.item_type == 'Three_column'">
				<div class="hot-product-head flex-center flex-zBetween">
					<div class="hot-product-tit flex-center">
						{{item.title}}
						<div class="split-line"></div>
					</div>
				</div>
				
				<div class="hot-product-body">
					<el-row :gutter="32">
						<el-col :xs="12" :sm="8" :lg="8" v-for="(ite,idx) in item.content" :key="idx">
							<router-link to="">
								<div class="product-box">
									<div class="product-pic">
										<img :src="item.pic[idx]">
									</div>
									<div  class="product-content">
										<div class="product-tit">{{item.ltitle[idx]}}</div>
										<div class="product-desc">{{ite}}</div>
									</div>
									<!-- <div v-show="lang=='en-US'" class="product-content">
										<div class="product-tit">{{ite.title_en}}</div>
										<div class="product-desc">{{ite.desc_en}}</div>
									</div> -->
								</div>
							</router-link>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="section" v-if="item.item_type == 'picture'">		
				<div class="section-head" >
					<div  class="section-title">{{item.title}}</div>
					<!-- <div v-show="lang=='en-US'" class="section-title">An Inside look at our Plant</div> -->
					<div class="sec-line"></div>
				</div>
				<div class="steps-cell-desc">
					<div v-html="item.content"></div>
				</div>
				<div class="section-body">
					<div class="container">
						<div class="com-box">
							<div class="clearfix">
								<div class=" pull-left1 pull-center">
									<div class="com-pic1" v-for="(itx,ind) in item.pic" :key="ind">
										<img :src="itx">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot :nvlang="lang"/>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import {mapState} from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				swiperOptionTop: {
					loop: true,
					autoplay: true,
					loopedSlides: 1,
				},
				banner: require('../assets/images/material_banner.jpg'),
				// img1: require('../assets/images/m1.png'),
				// img2: require('../assets/images/m2.png'),
				// img3: require('../assets/images/m3.png'),
				// img4: require('../assets/images/m4.png'),
				// img5: require('../assets/images/m5.png'),
				// img6: require('../assets/images/m6.png'),
				// img7: require('../assets/images/m7.png'),
				// img8: require('../assets/images/stamping.jpg'),
				// img9: require('../assets/images/m9.png'),
				// img10: require('../assets/images/equi1.png'),
				// img11: require('../assets/images/equi2.png'),
				// lang:'',
				competencies:{
					total:5,
					title:'生产运营及工程',
					title_en:'Engineering capability',
					data:[
						// {
						// 	title:' 工程设计能力',
						// 	title_en:'Design software and tools',
						// 	desc:['6 模具设计工程师', '6 工艺/制程工程师'],
						// 	desc_en:['6 tools design engineers','6 process engineers'],
						// 	img:[require('../assets/images/eg01.png'),require('../assets/images/eg02.png')],
						// 	wrap: 0
						// },
						// {
						// 	title:'模具制作',
						// 	title_en:'In-house Tool fabrication',
						// 	desc:[],
						// 	desc_en:[],
						// 	img:[require('../assets/images/eg03.png'),
						// 	require('../assets/images/eg04.png'),
						// 	require('../assets/images/eg05.png'),
						// 	require('../assets/images/eg06.png'),],
						// 	wrap: 1
						// },
						// {
						// 	title:'高速连续冲压 ',
						// 	title_en:'High-speed progressive stamping',
						// 	desc:['日本45T-60T高速高精度压力机'],
						// 	desc_en:['45T-60T Japan made high speed & high precision presses'],
						// 	img:[require('../assets/images/eg07.png'),
						// 	require('../assets/images/eg08.png'),
						// 	],
						// 	wrap: 0
						// },
						// {
						// 	title:'冲压成形',
						// 	title_en:'Stamping processes',
						// 	desc:['中低速冲压','单、双曲柄压力机范围从25T-110T'],
						// 	desc_en:['Low-middle speed presses','Single and double crank presses Ranging from 25T-110T'],
						// 	img:[require('../assets/images/eg09.png'),
						// 	require('../assets/images/eg10.png'),],
						// 	wrap: 0
						// },
						// {
						// 	title:'综合能力一览',
						// 	title_en:'Competencies',
						// 	desc:[],
						// 	desc_en:[],
						// 	img:[require('../assets/images/ep13.png'),
						// 		require('../assets/images/ep14.png'),],
						// 	wrap: 1
						// },
						
					]
					
				}
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'Competencies')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}
			},
			slide(){
				var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				if(slide && slide.length>0){
					// console.log(JSON.stringify(slide))
					return slide[0].pictures.split(',');
				}else{
					return [this.banner]
				}	
			},
			pageitems(){
				var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				var items = []
				var ob = {}
				var pic = []
				var title, ltitle, content
				for (let it of itemlist){
					// console.log(JSON.stringify(it))
					if(it.item_type =='Three_column'){
						ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
						content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
					// }else if(it.item_type !='picture' && it.item_type !='analysis'&&it.item_type !='onlytitle'){
					}else if( it.item_type !='analysis'&&it.item_type !='onlytitle'){
						// console.log(JSON.stringify(it))
						if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
							content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
						}else if(it.content !='null'&&it.content){
							content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
						}else{
							content = ''
						}
					}else{
						content=this.lang == 'en-US'?it.content_en:it.content
					}
					if(it.pictures && it.pictures.length>0){
						pic=it.pictures.split(',')
					}
					title = this.lang == 'en-US'?it.title_en :it.title
					ob = {title:title,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
					items.push(ob)
				}
				return items
			},
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			// langchange(data){
			// 	this.lang = data;
			// },
			// goTo(url) {
			// 	this.$router.push(url);
			// }
		}
	}
</script>
